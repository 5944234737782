







import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import FrameworkList from '@/components/framework/FrameworkList.vue';
import FrameworkHeader from '@/components/framework/FrameworkHeader.vue';

@Component({
  components: {
    FrameworkList,
    FrameworkHeader,
  },
})
export default class FrameworkView extends Vue {
}
