


































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import FrameworkService from '@/services/FrameworkService';
import { FrameworkModal } from '../../models';
import OverlayLoader from '../shared/OverlayLoader.vue';

@Component({
  components: {
    OverlayLoader,
  },
})
export default class FrameworkList extends Vue {
  isLoading: boolean = false;
  frameworkList: Array<FrameworkModal> = new Array<FrameworkModal>();

  @Watch('environment')
  onEnvironmentChange(value: string, old: string) {
    this.isLoading = true;
    FrameworkService.getAll(this.environment)
      .then((frameworks: FrameworkModal[]) => {
        this.isLoading = false;
        this.frameworkList = frameworks;
      });
  }

  get environment(): string {
    return this.$store.state.environment;
  }

  mounted(): void {
    this.isLoading = true;
    FrameworkService.getAll(this.environment)
      .then((frameworks: FrameworkModal[]) => {
        this.isLoading = false;
        this.frameworkList = frameworks;
      });
  }
}
